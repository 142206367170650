<template>
  <div>
    <div class="head">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-head"
        mode="horizontal"
        @select="handleSelect"
        text-color="black"
      >
        <el-menu-item class="menu-item" index="1" @click="goToHomePage"
          >首页</el-menu-item
        >
        <el-menu-item class="menu-item" index="2">产品与解决方案</el-menu-item>
        <el-menu-item class="menu-item" index="3">精确定位</el-menu-item>
        <el-menu-item class="menu-item" index="4">数字孪生</el-menu-item>
        <el-menu-item class="menu-item" index="5">动作分析</el-menu-item>
      </el-menu>
    </div>
    <!-- 登录、注册 -->
    <div class="top-bar">
      <img src="../assets/home_logo.png" class="head-menu-image" />
      <div class="head-menu-item">金陵佰思</div>
      <div class="search-input">
        <el-input
          placeholder="请输入关键词，按回车搜索"
          prefix-icon="el-icon-search"
          v-model="searchInput"
        >
        </el-input>
      </div>
      <div class="head-login relative">
        <div class="head-login-btn" @click="login">登录/注册</div>
        <img class="head-login-avater" src="../assets/home_logo.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {},
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      searchInput: "",
    };
  },
  methods: {
    selectAction(value) {
      console.log("value:", value);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    goToHomePage() {
      this.$router.push("/");
    },
    login(){
     this.$router.push("/login"); 
    }
  },
};
</script>

<style scoped>
.relative {
  position: relative;
}
.head {
  height: 69px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 19px;
}
.head-menu {
  display: flex;
}
.head-menu-image {
  width: 30px;
  height: 30px;
  margin-left: 50px;
  margin-right: 19px;
}
.head-menu-item {
  height: 26px;
  line-height: 26px;
  margin-right: 62px;
}

/* element样式修改 */
.el-dropdown-link {
  cursor: pointer;
  color: black;
  font-size: 19px;
}
.el-icon-arrow-down {
  font-size: 19px;
}
.menu-item {
  margin: 0 30px !important;
}
.el-menu-head {
  width: 100%;
  display: flex;
  justify-content: center;
}
/* 登录、注册 */
.top-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
}
.head-menu-image {
  width: 60px;
  height: 60px;
  margin-left: 50px;
  margin-right: 19px;
}
.head-menu-item {
  height: 26px;
  line-height: 26px;
  margin-right: 62px;
}
.search-input {
  width: 600px;
  margin-right: 30px;
}
.head-login-btn {
  height: 26px;
  line-height: 26px;
}
.head-login-avater {
  width: 50px;
  height: 50px;
  margin-left: 10px;
}
</style>
