<template>
  <div id="app">
    <Header></Header>
    <router-view />
    <Footer
      @seeBlackList="seeList"
      @seeIntroduction="introduction"
      @seesupervision="supervision"
      @regularAnnouncement="regularAnnouncement"
      @importantAnnouncement="importantAnnouncement"
      @recruit="recruit"
    ></Footer>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  methods: {
    seeList() {
      console.log("111");
      this.$router.push("/blackListQuery");
    },
    introduction() {
      this.$router.push("/introduction");
    },
    supervision() {
      this.$router.push("/supervision");
    },
    regularAnnouncement() {
      this.$router.push("/regularAnnouncement");
    },
    importantAnnouncement() {
      this.$router.push("/importantAnnouncement");
    },
    recruit() {
      this.$router.push("/recruit");
    },
  },
};
</script>
<style lang="scss">
@import "@/style/style.css";
@import "pdfjs-dist/web/pdf_viewer.css";

* {
  padding: 0;
  margin: 0;
}
.app {
  width: 1920px;
}
</style>
