import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/blackListQuery",
    name: "blackListQuery",
    component: () => import("../views/blackList/blackListQuery.vue"),
  },
  {
    path: "/blackList",
    name: "blackList",
    component: () => import("../views/blackList/blackList.vue"),
  },
  {
    path: "/introduction",
    name: "introduction",
    component: () => import("../views/introduction/introduction.vue"),
  },
  {
    path: "/supervision",
    name: "supervision",
    component: () => import("../views/supervision/supervision.vue"),
  },
  {
    path: "/regularAnnouncement",
    name: "regularAnnouncement",
    component: () => import("../views/announcement/regularAnnouncement.vue"),
  },
  {
    path: "/importantAnnouncement",
    name: "importantAnnouncement",
    component: () => import("../views/announcement/importantAnnouncement.vue"),
  },{
    path: "/recruit",
    name: "recruit",
    component: () => import("../views/recruit/recruit.vue"),
  },
  {
    path: '/productDownload',
    name: 'productDownload',
    component: () => import('../views/productDownload.vue')
  },
  {
    path:'/Recruitment',
    name:'Recruitment',
    component:()=>import('../views/Recruitment.vue')
  
  },
  {
    path:'/DigitalTwin',
    name:'DigitalTwin',
    component:()=>import('../views/DigitalTwin.vue')
  },
  {
    path:'/GreenHouse',
    name:'GreenHouse',
    component:()=>import('../views/GreenHouse.vue')
  },
  {
    path:'/Privacy',
    name:'Privacy',
    component:()=>import('../views/Privacy.vue')
  },
  {
    path:'/Application',
    name:'Application',
    component:()=>import('../views/Application.vue')
  },
];


const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
