import { get, post } from "./request";

// const rootUrl = "http://212.64.72.35:8090/baisea-holding-api/";
// const rootUrl = 'http://192.168.31.131:8090/baisea-holding-api/'

// 解决http请求https不安全问题
const rootUrl = "https://www.baisea.info/baisea-holding-api/";

const api = {
  getTChannelsService: rootUrl + "tAlbumPhotos/getTAlbumPhotosController", // 轮播图接口

  getImage: rootUrl + "tChannels/getTChannelsController", // 查询频道图片

  tConfColumns: rootUrl + "tConfColumns/columnListQuery", // 获取栏目列表

  staffBlackInsert: rootUrl + "tStaffBlacklist/staffBlackInsert", //添加员工黑名单

  staffBlackListDelete: rootUrl + "tStaffBlacklist/staffBlackListDelete", //删除员工黑名单

  staffBlackListEdit: rootUrl + "tStaffBlacklist/staffBlackListEdit", //编辑员工黑名单

  staffBlackListQuery: rootUrl + "tStaffBlacklist/staffBlackListQuery", //获取员工黑名单

  ptnrBlackInsert: rootUrl + "tPtnrBlacklist/ptnrBlackInsert", //添加合作伙伴黑名单
  ptnrBlackListDelete: rootUrl + "tPtnrBlacklist/ptnrBlackListDelete", //删除合作伙伴黑名单
  ptnrBlackListEdit: rootUrl + "tPtnrBlacklist/ptnrBlackListEdit", //编辑合作伙伴黑名单
  ptnrBlackListQuery: rootUrl + "tPtnrBlacklist/ptnrBlackListQuery", //获取合作伙伴黑名单

  // 查询频道图片
  getImage: rootUrl + "tChannels/getTChannelsController",
  // 获取栏目列表
  tConfColumns: rootUrl + "tConfColumns/columnListQuery",
  // 获取下载列表
  tcPdCenter: rootUrl + "tcPdCenter/pdCenterListQuery",

  // 查询发布
  tcPdCenterss: rootUrl + "tFileUploads/tFileUploadsSelect?status=5",

  // 获取业务列表
  tcBizeDtws: rootUrl + "tcBizDtws/bizDtwsListQuery",
};

export default api;
