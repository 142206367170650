<template>
  <div class="home">
    <!-- <div class="top-bar">
      <img src="../assets/home_logo.png" class="head-menu-image" />
      <div class="head-menu-item">金陵佰思</div>
      <div class="search-input">
        <el-input
            placeholder="请输入关键词，按回车搜索"
            prefix-icon="el-icon-search"
            v-model="searchInput">
        </el-input>
      </div>
      <div class="head-login relative">
        <div class="head-login-btn">登录/注册</div>
        <img class="head-login-avater" src="../assets/home_logo.png" />
      </div>
    </div> -->
    <div class="home-slider">

      <!-- 获取轮播图 -->
      <el-carousel trigger="click" height="642px">
        <el-carousel-item v-for="(item,index) in records" :key="index">
          <img class="home-slider" :src="item.photoUrl" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 获取频道和栏目 -->
    <div class="content">
      <div class="content-box">
        <div class="content-line" v-for="(item,index) in channel" :key="index">
        <div class="content-line-title">{{ item.chnlName }}</div>
        <div class="content-line-chunk">
          <div
            class="line-chunk-block"
            v-for="(subItem,index) in item.children"
            :key="index"
          >
            <img class="chunk-block-img" :src="subItem.fnameUrl" />
            <div class="chunk-block-name">{{ subItem.fileTitle }}</div>
            <div class="chunk-block-extra">{{ subItem.fileIntro }}</div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      records: [],
      channel: [],
      searchInput: ""
    };
  },
  mounted() {
    this.getHomeSlider();
    this.getHomeBlockImage();
  },
  methods: {
    

    /* 接口 */

    // 获取首页轮播图
    getHomeSlider() {
      this.$request
        .requestGet(this.$api.getTChannelsService, {
          isRotational:"1",
          status:1,
          pageSize: 3,
        })
        .then((res) => {
          console.log("@@@@@",res);
          this.records = res.data.records;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取首页块图片
    getHomeBlockImage() {
      this.$request
        .requestGet(this.$api.getImage, {
          ChannelsStatus:1,
          tFileUploadStatus: 1,         
          tFileUploadLimit: 2,
        })
        .then((res) => {
           this.channel = res.data
     
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //
  },
};
</script>
<style scoped>
.home {
  background-color: #ffffff;
}
.home-slider {
  width: 100%;
  height: 642px;
}
.content {
  width:100%;
  display: flex;
  flex-direction: column;
}
.content-box{
  left: 0;
  right: 0;
  margin: auto;
  /* width: 1360px; */
  /* margin-left: 280px; */
}
.content-line {
  display: flex;
  flex-direction: column;
  margin: 0 40px 0 0;
  padding: 60px 0 80px;
}
.content-line-title {
  color: #242424;
  font-size: 40px;
  height: 56px;
  line-height: 56px;
}
.content-line-chunk {
  display: flex;
  align-items: center;
  margin-top: 35px;
}
.line-chunk-block {
  flex:0 0 50%;
  display: flex;
  flex-direction: column;

  color: #242424;
}
.line-chunk-block + .line-chunk-block {
  margin-left: 40px;
}
.chunk-block-img {
  width: 660px;
  height: 371px;
}
.chunk-block-name {
  font-size: 22px;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
}
.chunk-block-extra {
  font-size: 16px;
  height: 22px;
  line-height: 22px;
  margin-top: 5px;
}.head-menu-image {
   width: 60px;
   height: 60px;
   margin-left: 50px;
   margin-right: 19px;
 }
.head-menu-item {
  height: 26px;
  line-height: 26px;
  margin-right: 62px;
}
.head-login {
  margin-right: 110px;
  display: flex;
  align-items: center;
}
.head-login-btn {
  height: 26px;
  line-height: 26px;
}
.head-login-avater {
  width: 50px;
  height: 50px;
  margin-left: 10px;
}
.top-bar{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
}
.search-input{
  width: 600px;
  margin-right: 30px;
}
</style>
