<template>
  <div class="footer-box">
    <div class="footer">
      <!--    <div class="footer-name">-->
      <!--      <img class="footer-name-img" src="../assets/home_logo.png" />-->
      <!--      <div class="footer-name-text">南京佰思信息技术有限公司</div>-->
      <!--    </div>-->
      <div class="footer-column">
        <div class="footer-column-line strong">主营业物</div>
        <div class="footer-column-line">精确定位</div>
        <div class="footer-column-line">动作分析</div>
        <div class="footer-column-line hover"  @click="DigitalTwin">数字孪生</div>
        <div class="footer-column-line">智慧管理</div>
      </div>
      <div class="footer-column">
        <div class="footer-column-line strong">合作伙伴</div>
        <div class="footer-column-line">供应商</div>
        <div class="footer-column-line">代理商</div>
        <div class="footer-column-line">在线资料</div>
      </div>
      <div class="footer-column">
        <div class="footer-column-line strong">查询与举报</div>
        <div class="footer-column-line hover" @click="blackList">禁止合作名单</div>
        <div class="footer-column-line hover" @click="supervision">监督与举报</div>
      </div>
      <div class="footer-column">
        <div class="footer-column-line strong">投资者</div>
        <div class="footer-column-line hover" @click="importantAnnouncement">
          要事公告
        </div>
        <div class="footer-column-line hover" @click="regularAnnouncement">
          定期报告
        </div>
      </div>
      <div class="footer-column">
        <div class="footer-column-line strong">快速入口</div>
        <div class="footer-column-line">大数据</div>
        <div class="footer-column-line">人工智能</div>
        <div class="footer-column-line hover" @click="recruit">人才招聘</div>
        <div class="footer-column-line hover" @click="seeIntroduction">公司介绍</div>
      </div>
      <div class="footer-column">
        <div class="footer-column-line strong">相关产品</div>
        <div class="footer-column-line hover" @click="greenHouse">绿居中国</div>
        <div class="footer-column-line">佰思亿展</div>
        <div class="footer-column-line">佰思体育</div>
        <div class="footer-column-line">佰思友邦</div>
      </div>
      <div class="footer-column">
        <div class="footer-column-line strong">研发基地</div>
      </div>
      <div class="footer-qrcode">
        <img class="footer-qrcode-img" src="../assets/location.png" />
      </div>
    </div>
    <div class="footer-info">
      <div><a class="special-a" target="_blank" href="https://beian.miit.gov.cn">苏ICP备18044019号</a> 版权所有 © 南京佰思信息技术有限公司</div>
      <div>
        All Rights Reserved, Copyright 2018-2022 @ Nanjing Baisea Infotek
        Co.,Ltd
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props:['seeBlackList'],
  name: "Footer",
  data() {
    return {
      msg: "",
    };
  },
  methods: {
    blackList() {
      console.log("222");
      this.$emit("seeBlackList");
    },

    // 去安装软件页面
    toSoftwareDownPage() {
      this.$router.push({ path: "productDownload" });
    },
    blackList() {
      console.log("222");
      this.$emit("seeBlackList");
    },
    seeIntroduction() {
      this.$emit("seeIntroduction");
    },
    supervision() {
      this.$emit("seesupervision");
    },
    regularAnnouncement() {
      this.$emit("regularAnnouncement");
    },
    importantAnnouncement() {
      this.$emit("importantAnnouncement");
    },
    recruit() {
      this.$emit("recruit");
    },
    // 加入我们
    join() {
      this.$router.push({ path: "Recruitment" });
    },
    // 数字孪生
    DigitalTwin() {
      console.log("数字孪生");
      this.$router.push({ path: "DigitalTwin" });
    },
    // 绿居
    greenHouse() {
      console.log("绿居");
      this.$router.push({ path: "GreenHouse" });
    },
  },
};
</script>

<style scoped>
.footer-box {
  background-color: #f0f0f0;
}
.footer {
  box-sizing: border-box;

  height: 400px;
  width: 70%;
  margin: 0 auto;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  place-content: space-between;
  color: black;
}
.footer-name {
  display: flex;
  align-items: center;
  margin-left: 315px;
  margin-right: 36px;
}
.footer-name-img {
  width: 56px;
  height: 56px;
}
.footer-name-text {
  margin-left: 10px;
  height: 22px;
  line-height: 22px;
  font-size: 16px;
}
.footer-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 77px 15px;
  font-size: 14px;
  width: 140px;
}
.footer-column-line {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
}
.footer-column-line + .footer-column-line {
  margin-top: 17px;
}
.footer-qrcode {
  width: 645px;
  height: 331px;
  /* background-color: #ffffff; */
  margin: 18px 0 25px -90px;
}
.footer-qrcode-img {
  width: 100%;
  height: 100%;
}

.strong {
  font-weight: bold;
  font-size: 18px;
}
.footer-info {
  background-color: #ffffff;
  text-align: center;
  padding: 20px;
  color: #666666;
}

.hover:hover{
  color: #409EFF;
}

.special-a{
  text-decoration:none;
  color: #666;
}
</style>
